<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <PageAdd
      :role="role"
      :model="model"
      :url="`${$api.servers.job}/api/v1/en/moderator/job/add`"
      action="add"
      :callbackSuccess="callbackSuccess"
    ></PageAdd>
    <!-- EOC -->
  </v-container>
  </template>
  
  <script>
    //BOC:[model]
    import model from '@/models/items/job'
    //EOC
     import PageAdd from "@/components/Moderator/Job/PageAdd";
    import { mapState } from 'vuex'
    export default {
      components:{
        PageAdd,
      },
      computed: mapState({
        school: state => state.school.data,
      }),
      props:[
        //
      ],
      data: () => ({
        //BOC:[breadcrumbs]
        breadcrumbs: [],
        //EOC
        //BOC:[model]
        model: model,
        //EOC
        //BOC:[role]
        role:'Moderator',
        //EOC
      }),
      created() {
        //BOC:[breadcrumbs]
        this.breadcrumbs.push({
          text:this.$t("model.name.dashboard"),
          to:{name:'Page'+this.role+'Dashboard'},
          exact:true,
        })
        //
        this.breadcrumbs.push({
          text:this.$t("model.name." + this.model.name.plural.toLowerCase()),
          to:this.$_getRouteBrowse(this.role,this.model.key),
          exact:true,
        })
        //
        this.breadcrumbs.push({
          text:`${this.$t("string.bread_new")}${this.$t("model.name." + this.model.name.singular.toLowerCase())}`,
          to:this.$_getRouteAdd(this.role,this.model.key),
          exact:true,
        })
        //EOC
      },
      mounted() {
        //
      },
      methods: {
        callbackSuccess() {
          this.$router.push({name:'PageModeratorJobBrowse'})
        },
      }
    }
  </script>