<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>New Job</v-card-title>
        <v-card-text>
          <template>
            <FormInput :value="form['name']" @input="form['name'] = $event" />
          </template>
          <template>
            <FormTextarea
              :value="form['data']"
              @input="form['data'] = $event"
            />
          </template>
          <template>
            <FormDate
              :callbackSelect="selectDate"
              formKey="dateSchedule"
              name="Schedule Date"
              :value="form.dateSchedule"
            />
          </template>
          <template>
            <FormTime
              :callbackSelect="selectTime"
              formKey="timeSchedule"
              name="Schedule Time"
              :value="form.timeSchedule"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormTextarea from "@/components/Moderator/Job/FormTextarea";
import FormInput from "@/components/Moderator/Job/FormInput";
import FormDate from "@/components/Moderator/Job/FormDate";
import FormTime from "@/components/Moderator/Job/FormTime";

import moment from "moment";
import { mapState } from "vuex";
export default {
  props: ["self", "role", "model", "url", "action", "callbackSuccess"],
  components: {
    FormTextarea,
    FormInput,
    FormDate,
    FormTime,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[form]
    form: {
      name: null,
      data: '{"url":"http://localhost:8007/api/v1/en/telegramUser/blast","method":"post","body":{"message": "Hello"}}',
      dateSchedule: moment().format("YYYY-MM-DD"),
      timestampScheduled: moment().toISOString(),
      timeSchedule: "08:00",
    },
    fields: [],
    rules: {},
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    entertName(name) {
      this.form.name = name;
    },
    selectDate(date) {
      this.form.dateSchedule = date;
    },
    selectTime(time) {
      this.form.timeSchedule = time;
    },
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.form.timestampScheduled = moment(
          this.form.dateSchedule + " " + this.form.timeSchedule
        ).toISOString();

        this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
};
</script>

<style>
</style>